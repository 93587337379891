<template>
  <div class="wrapper">
    <div class="items">
      <div class="items-input">
      <el-select
        v-model="_item.key"
        :placeholder="'字段'"
        :teleported="false"
        :suffix-icon="arrow"
      >
        <el-option
          :label="1"
          :value="1"
        >
        </el-option>
        <el-option
            :label="2"
            :value="2"
          >
          </el-option>
      </el-select>
      <el-select
        v-model="_item.relation"
        :placeholder="'关系'"
        :teleported="false"
        :suffix-icon="arrow"
      >
        <el-option
          :label="1"
          :value="1"
        >
        </el-option>
        <el-option
            :label="2"
            :value="2"
          >
          </el-option>
      </el-select>
      <el-input v-model="_item.value" placeholder="值"></el-input>
    </div>
    <div class="items-operate">
      <span @click="addItems">+</span>
      <span @click="emits('deleteItems')">-</span>
      <el-dropdown
        trigger="click"
        placement="bottom-end"
        :hide-on-click="true"
        popper-class="advanced-operate"
      >
        <span style="line-height:22px;">...</span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item :disabled="index === 0" @click="up(filters, index)">上移</el-dropdown-item>
            <el-dropdown-item :disabled="index === filters.length - 1" @click="down(filters, index)">下移</el-dropdown-item>
            <el-dropdown-item>
              <el-dropdown
              placement='left-start'
              popper-class="advanced-operate"
              trigger="hover"
              :hide-on-click="true"
              :popper-options="{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 20],
                    },
                  },
                ],
              }"
              >
                <span class="arrow-item">
                  移至分组
                  <el-icon class="el-icon--right">
                    <arrow-right />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item v-for="(item, index) in groupsItem" :disabled="groupIndex === index - 1" :key="index" @click="emits('removeItems', index)">{{item}}</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-dropdown-item>
            <el-dropdown-item v-if="isGroup" @click="emits('toSingleItems')">移出分组</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, toRefs, computed, ref } from 'vue'
import { ArrowRight } from '@element-plus/icons-vue'
const props = defineProps({
  filters: () => [],
  item: () => {},
  index: () => Number,
  groups: () => [],
  groupIndex: () => Number,
  isGroup: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['deleteItems', 'removeItems', 'toSingleItems', 'update:item'])
const { filters, item, index, groups, groupIndex, isGroup } = toRefs(props)
const _item = ref(item.value)
const addItems = () => {
  filters.value.push({
    key: '',
    relation: '',
    value: ''
  })
}

const groupsItem = computed(() => {
  const arr = groups.value.filter((item) => item.filters.length !== 0).map((item) => item.name)
  arr.unshift('新分组')
  return arr
})

const up = (arr, index) => {
  if (index === 0) return
  [arr[index - 1], arr[index]] = [arr[index], arr[index - 1]]
}
const down = (arr, index) => {
  if (index === arr.length - 1) return
  [arr[index], arr[index + 1]] = [arr[index + 1], arr[index]]
}
</script>
<style lang="scss">
.advanced-operate {
  border: 0 !important;
  // box-shadow:2px 2px 10px black !important;
  box-shadow: var(--shadow) !important;
  .el-popper__arrow {
    display: none;
  }
  .disabled {
    cursor: not-allowed;
    color: #525966;
  }
  .arrow-item {
    width: 100px;
    height: 22px;
    display:flex;
    justify-content:space-between;
    align-items:center;
  }
}
</style>
<style lang="scss" scoped>
.items {
  display: flex;
  &-input {
    display: flex;
    flex: 1;
    & > div {
      margin-right: 8px;
      &:nth-child(1) {
        flex: 2;
      }
      &:nth-child(2) {
        flex: 1;
      }
      &:nth-child(3) {
        flex: 6;
      }
    }
  }
  &-operate {
    height: 32px;
    span {
      box-sizing: border-box;
      display: inline-block;
      width: 32px;
      margin-left: 8px;
      line-height: 32px;
      height: 32px;
      border: 1px solid #5a5e6e;
      font-size: 18px;
      color: var(--color-text-2);
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
